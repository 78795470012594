import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import { ScreenRecognitionProvider } from '@reckitt/phx-husky.contexts.screen-recognition';

export const wrapRootElement = ({ element }) => (
  <>
    <Script id="gtm-init" strategy={ScriptStrategy.idle}>
      {`
        setTimeout(function(){
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
        })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAG_ID}'); 
        }, 4000)
      `}
    </Script>
    <noscript>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_TAG_ID}`}
        height="0"
        width="0"
      />
    </noscript>

    <ScreenRecognitionProvider>{element}</ScreenRecognitionProvider>
  </>
);
